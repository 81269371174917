import "./import-jquery.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/@popperjs/core/dist/cjs/popper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../node_modules/fancybox/dist/css/jquery.fancybox.css";
import "../node_modules/fancybox/dist/js/jquery.fancybox.js";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../css/style.css"



// import "@fancyapps/ui/dist/fancybox.css";
import "slick-carousel/slick/slick.js";
// import { Fancybox } from "@fancyapps/ui";



$('.client-reviews-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: true,
});

$("a.fancybox").fancybox();


const FontFaceObserver = require('fontfaceobserver');
const font = new FontFaceObserver('Poppins', 'sans-serif');
font.load().then(function () {

});


const sideForm = document.getElementById('sideFixedForm');

sideForm.addEventListener('click', function (e) {
    e.preventDefault();
    const target = document.querySelector('.floating_form');
    if (target.classList.contains('open')) {
        target.classList.remove('open');

    } else {
        target.classList.add('open');
    }
})


document.querySelector(".open-chat-widget").addEventListener('click', function (event) {
    if (event.target.dataset.chatWidgetStatus === 'opened') {
        zE('messenger', 'close');
        event.target.dataset.chatWidgetStatus = 'closed';

    } else {
        zE('messenger', 'open');
        event.target.dataset.chatWidgetStatus = 'opened';
    }


})

document.querySelectorAll(".open-chat-widget").forEach(function (chatBtn) {
    chatBtn.addEventListener('click', function (event) {
        // event.stopImmediatePropagation()
        event.stopPropagation()
        if (event.target.dataset.chatWidgetStatus === 'opened') {
            zE('messenger', 'close');
            event.target.dataset.chatWidgetStatus = 'closed';


        } else {
            zE('messenger', 'open');
            event.target.dataset.chatWidgetStatus = 'opened';

        }
    })
})

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $('#blah')
                .attr('src', e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
    }
}

AOS.init();


// <!-- TrustBox script -->
(function (w, d, s, r, n) {
    w.TrustpilotObject = n;
    w[n] = w[n] || function () {
        (w[n].q = w[n].q || []).push(arguments)
    };
    a = d.createElement(s);
    a.async = 1;
    a.src = r;
    a.type = 'text/java' + s;
    f = d.getElementsByTagName(s)[0];
    f.parentNode.insertBefore(a, f)
})(window, document, 'script', 'https://invitejs.trustpilot.com/tp.min.js', 'tp');
tp('register', 'K0te3w2zQKqro0Ui');

